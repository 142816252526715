import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const DyingLight2WhichMonster = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";

  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #FF0B10, #E72C2F, #CF0004, #D81014, #EF1115 )",
        icons: "#FF0B10",
        navClass: "dl2-monster",
      }}
      seo={{
        title: "Dying Light 2 WHICH MONSTER",
        headerTitle: "dl2-wm",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/dl2-which-monster_og-image.jpg"),
      }}
      // langSwitch={{
      //     langKey: "en",
      //     langSlug: "/en/projects/dl2-which-monster/",
      // }}
    >
      <PortfolioHeader name="dl2-which-monster" height="276" />
      <section className="container-fluid dl2-wm__section2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>
                Dying Light 2 <span>WHICH MONSTER</span>
              </h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Czasami mamy wrażenie, że jedyne czym się zajmujemy to walka z
                zombie:) Tym razem dla Techland wdrożyliśmy quiz online, który
                pozwala sprawdzić którym z potworów występujących w grze Dying
                Light 2 Stay Human jesteśmy. Pamiętajcie, aby na pytania zawarte
                w quizie odpowiadać szczerze.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-wm__section3">
        <div className="container">
          <div className="row">
            <div className="col-12 order-1 order-md-2 dl2-wm__section3--laptop">
              <h2>Konkret</h2>
              <p>
                Strona WHICH MONSTER to jeden z mikroserwisów wspierający
                działania promocyjne związane z premierą długo wyczekiwanej
                przez graczy na całym świecie, kontynuacji światowego
                bestsellera autorstwa Techlandu - Dying Light. Rozwiązanie quizu
                pozwala dowiedzieć się w którego z potworów zamienisz się po
                zainfekowaniu wirusem Harran.
              </p>
              {/* <img src={require("../../assets/img/portfolio/dl2-which-monster_laptop.gif")} alt="Which monster Quiz screenshot" /> */}
            </div>
          </div>
        </div>

        <div className="row dl2-wm__section3__laptop-row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--macbook">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/dl2-which-monster_laptop.gif")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1 dl2-wm__section3--mobiles">
              <img
                src={require("../../assets/img/portfolio/dl2-which-monster_mobile_front.png")}
                alt="Day light 2 Mobile phone screenshot"
              />
              <img
                src={require("../../assets/img/portfolio/dl2-which-monster_mobile_back.png")}
                alt="Day light 2 Mobile phone screenshot"
              />
            </div>
            <div className="col-md-6 order-1 order-md-2 dl2-wm__section3--tasks">
              <h2>Zadanie</h2>
              <p>1. Wdrożyć quiz w formie jedno ekranowej opowieści</p>
              <p>2. Mechanikę serwisu oprzeć o scroll i system ankietowy</p>
              <p>
                3. Uwzględnić nieco interakcji a całość podlać oficjalnym,
                mrocznym motywem muzycznym Dying Light 2 Stay Human
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-wm__section4 px-0">
        <img
          src={require("../../assets/img/portfolio/dl2-which-monster_banner.jpg")}
          alt="Day light 2 screenshot"
        />
        <img
          src={require("../../assets/img/portfolio/dl2-which-monster_hero.png")}
          alt="Day light 2 hero"
        />
        <img
          src={require("../../assets/img/portfolio/dl2-which-monster_lang.png")}
          alt="Day light 2 screenshot"
        />
      </section>

      <section className="container-fluid dl2-wm__section5">
        <div className="row">
          <div className="col-md-6 dl2-wm__section5__col-left">
            <img
              src={require("../../assets/img/portfolio/dl2-which-monster_screen1.png")}
              alt="Day light 2 screenshots"
            />
            <img
              src={require("../../assets/img/portfolio/dl2-which-monster_screen2.png")}
              alt="Day light 2 screenshots"
            />
            <div className="dl2-wm__list">
              <h2>Efekt</h2>
              <p>
                1. Projekt jest rozwinięciem wdrażanego przez nas serwisu
                głównego gry, więc jak i on sam został on zrealizowany w oparciu
                o Gatsby.js
              </p>
              <p>
                2. Po stronie poruszamy się za pomocą scrolla lub strzałek na
                klawiaturze
              </p>
              <p>
                3. Mroczna muzyka z gry dodaje klimatu - koniecznie włączcie
                głośniki :)
              </p>
            </div>
          </div>
          <div className="col-md-6 dl2-wm__section5__col-right">
            <img
              src={require("../../assets/img/portfolio/dl2-which-monster_screen3.png")}
              alt="Day light 2 screenshots"
            />
            <img
              src={require("../../assets/img/portfolio/dl2-which-monster_screen4.png")}
              alt="Day light 2 screenshots"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-wm__section6 px-0">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1 dl2-wm__section6__col-left">
            <img
              src={require("../../assets/img/portfolio/dl2-which-monster_screen6.png")}
              alt="Day light 2 screenshots"
            />
          </div>
          <div className="col-md-6 order-1 order-md-2 dl2-wm__section6__col-right">
            <img
              src={require("../../assets/img/portfolio/dl2-which-monster_screen5.jpg")}
              alt="Day light 2 screenshots"
            />
            <div className="dl2-wm__list">
              <h2>Czy wiesz że?</h2>
              <p>
                Ten quiz to tylko żart, wokół nas nie ma przecież żadnego
                groźnego wirusa, są za to inne zombiakowe projekty z którymi
                możesz się zapoznać!
              </p>
              <p className="dl2-wm__section6__products">
                Dying Light 2 PRE-ORDER<br></br>
                Dying Light 2 Stay Human<br></br>
                Dying Light<br></br>
                Dying Light Nintendo Switch<br></br>
              </p>
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default DyingLight2WhichMonster;
